// Agency Theme JavaScript

(function ($) {
    "use strict"; // Start of use strict

    // jQuery for page scrolling feature - requires jQuery Easing plugin
    $('a.page-scroll').bind('click', function (event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top - 50)
        }, 1250, 'easeInOutExpo');
        event.preventDefault();
    });

    // Highlight the top nav as scrolling occurs
    $('body').scrollspy({
        target: '.navbar-fixed-top',
        offset: 51
    });

    $(".open-details-modal").on("click", function () {
        ga('send', 'Modal', $(this).data("detail"));
    })


    function initSlider() {
        var w = $(window).innerWidth();

        var mySwiper = new Swiper('.swiper-container', {
            loop: true,
            speed: 400,
            spaceBetween: 30,
            slidesPerView: w < 768 ? 1 : w < 992 ? 2 : 3,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
        });
    }

    initSlider();

    $(window).resize(initSlider);

})(jQuery); // End of use strict
    (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date(); a = s.createElement(o),
            m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    ga('set', 'anonymizeIp', true);
    ga('create', 'UA-69711127-1', ANALYTICS);